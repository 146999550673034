import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import favicon from '../../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

// images
import banner_img from "../../images/undersidor/e-faktura.svg";
import peppol from '../../images/peppol_icon.png';
import e_faktura from '../../images/e_faktura.png';
import edi from '../../images/edi.png';

const KONTAKT_BOT = "kxFV5wCG";

function E_faktura() {
    const [kontakt_1, set_kontakt_1] = useState(false);
    const [kontakt_2, set_kontakt_2] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>E-invoice | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Easily receive and send e-invoices in any format with Crediflow." />
                <meta name="keywords" content="E-invoice" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Easily receive and send e-invoices in any format with Crediflow." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="en_EN" />
                <meta property="og:url" content="https://www.crediflow.com/supplier-invoices/e-invoice" />
                <link rel="cannonical" href="https://www.crediflow.com/supplier-invoices/e-invoice" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">E-invoice, EDI and/or PEPPOL?</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Do all these words seem complicated and confusing?
                                        Maybe your supplier has asked to send
                                        an EDI invoice for you? Your customer probably means 
                                        e-invoice but says EDI. Let us
                                        help you, forget all the buzzwords and
                                        formats and focus on your business instead!
                                    </p>
                                    <button onClick={() => set_kontakt_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                                    {kontakt_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={KONTAKT_BOT} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:1/3 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="service och support optosweden" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Home</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <Link to="/supplier-invoices" className="flex items-center text-blue-800 hover:text-blue-600">supplier-invoices</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">e-invoice</span>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 pt-12" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">E-invoice</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                For incoming invoices, we adapt
                                according to your supplier's possibilities, we
                                handles the conversion of formats as well
                                validates information that is important to
                                you. You'll receive all invoices standardised
                                regardless of how the suppliers sent them.
                            </p>
                            <Link to="/demo" className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Book demo</Link>
                        </div>
                
                        <div className="w-2/3 p-4 mx-auto">
                            <img className="w-full" src={e_faktura} alt="e-faktura" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-28" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={peppol} alt="peppol" />
                        </div>
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">PEPPOL</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                We are active members of OPENPEPPOL and provide
                                Access point and acts as SMP register for our customers.
                                <br/><br/>
                                We help you with all questions about Peppol.
                                <br/><br/>
                                Peppol makes it possible for trading partners
                                to exchange standards-based electronic
                                document of the Peppol network that is
                                based on a 4-corner model.
                            </p>
                            <button onClick={() => set_kontakt_2(true)} className="px-7 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                            {kontakt_2 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={KONTAKT_BOT} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>

                    </div>
                </div>
            </section>

            <section className="bg-gray-100 py-28" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100 pb-32 " >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">EDI</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                We offer a complete solution for
                                EDI regardless of whether you want to send or receive EDI messages.
                                <br/><br/>
                                No double registration on any external website is needed.
                            </p>
                            <div className="mt-10 flex">
                                <Link to="/demo" className="px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Book demo</Link>
                                <Link to="/edi" className="ml-3 px-10 py-2 rounded-full text-blue-custome border border-blue-custome bg-white shadow-md hover:bg-blue-100">Read more</Link>
                            </div>

                        </div>
                    <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={edi} alt="edi" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default E_faktura;